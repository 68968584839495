<script>
/* eslint-disable */
import LeilaoMixin from "components/leiloes/components/LeilaoMixin"
import windowImportacao from "components/importador/windows/windowImportacao"
import {importacoes} from "@/domain/importador/services"
import * as Status from '@/domain/importador/helpers/Status'
import ERow from "components/layout/components/Row.vue";
import ECol from "components/layout/components/Col.vue";
import importacaoWindow from "components/importador/windows/importacao";

export default {
  name: 'Leilao',
  inject: ['page'],
  mixins: [LeilaoMixin],
  components: {ECol, ERow},
  data() {
    return {
      importacoes: null,
      loadingImportacoes: false
    }
  },
  computed: {
    Status () {
      return Status
    }
  },
  mounted() {
    this.loadImportacoes()
  },
  destroyed() {
  },
  methods: {
    loadImportacoes() {
      this.loadingImportacoes = true
      importacoes('lote', this.leilao.id)
          .then((response) => {
            console.log(response.data)
            this.importacoes = response.data
            this.loadingImportacoes = false
          })
          .catch(error => {
            this.alertApiError(error.response)
            this.loadingImportacoes = false
          })
    },
    windowImportacao,
    importacaoWindow
  },
  meta: {
    title: 'Importação',
    name: 'Importação'
  }
}
</script>

<template>
  <div class="">
    <div class="content">
      <header>
        <e-row mr>
          <e-col><h2 class="component-title">Histórico de importações</h2></e-col>
          <e-col class="text-right">
            <a href="https://docs.suporteleiloes.com/docs/leiloes/Importacao" target="_blank" class="m-r">Baixe o modelo de planilha</a>
            <u-btn @click="importacaoWindow('lote', leilao.id)" icon="file-import" icon-type="fa" label="Importar Planilha" color="green" no-caps class="b-radius-3px" />
            <u-btn @click="importacaoWindow('lote', leilao.id)" icon="file-zip" icon-type="fa" label="Importar Arquivo Zip" color="grey" no-caps class="b-radius-3px m-l" />
          </e-col>
        </e-row>
      </header>

      <div class="body">
        <div class="lista-importacoes">
          <div class="m-t" v-if="loadingImportacoes">Verificando importações disponíveis <i class="fa fa-spin fa-spinner"></i></div>
          <table v-if="importacoes && importacoes.length" class="erp-table w-full">
            <thead>
            <tr>
              <th class="text-left">ID</th>
              <th class="text-left">Tipo de importação</th>
              <th class="text-left">Situação</th>
              <th class="text-left">Data Início</th>
              <th class="text-left">Data Término</th>
              <th class="text-left">Quantidade de registros</th>
              <th class="text-left">Sucesso</th>
              <th class="text-left">Falha</th>
              <th class="text-right">Opções</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="importacao in importacoes" :key="importacao.id">
              <td class="text-left uppercase">{{ importacao.id }}</td>
              <td class="text-left uppercase">{{ importacao.type }}</td>
              <td class="text-left uppercase">{{ Status.Status[importacao.status].title }}</td>
              <td class="text-left"><span
                  v-if="importacao.dataInicio">{{ importacao.dataInicio.date|formatDate }}</span><span v-else>-</span>
              </td>
              <td class="text-left"><span
                  v-if="importacao.dataTermino">{{ importacao.dataTermino.date|formatDate }}</span><span v-else>-</span>
              </td>
              <td class="text-left">{{ importacao.totalItems }}</td>
              <td class="text-left">{{ importacao.sucessos }}</td>
              <td class="text-left">{{ importacao.falhas }}</td>
              <td class="text-right">
                <a @click="windowImportacao(importacao.id)">Visualizar</a>
              </td>
            </tr>
            </tbody>
          </table>

          <div class="m-t" v-else><span v-if="!loadingImportacoes">Nenhuma importação até o momento.</span></div>
        </div>
      </div>
    </div>
  </div>
</template>
